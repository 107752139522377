import React, { ReactNode } from "react";

interface CardProps {
  id: string;
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  selected?: boolean;
  onClick: () => void;
}

export default function Card({
  id,
  header,
  children,
  footer,
  selected,
  onClick,
}: CardProps) {
  return (
    <div
      className={
        selected
          ? "flex flex-col justify-between relative px-3 py-4 h-[140px] w-full rounded-md border-green-500 border-2 shadow-lg text-sky-500"
          : "flex flex-col justify-between relative px-3 py-4 h-[140px] w-full border-[1px] rounded-md hover:border-gray-300 hover:shadow-lg text-gray-600 hover:text-sky-500"
      }
      onClick={onClick}
    >
      <div className="mb-6">{header}</div>
      {children}
      {footer && (
        <div>
          <hr className="text-gray-500" />
          <div className="h-4">
            <span>{footer}</span>
          </div>
        </div>
      )}
    </div>
  );
}
