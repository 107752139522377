import React, { useState } from "react";
import { FaRocket } from "react-icons/fa";
import projectContent, { ProjectKey } from "./components/Projects";
import Sider from "../../common/components/Sider";
import Popover from "../../common/components/Popover";

export default function SpacePage() {
  const [selectedPage, setSelectedPage] = useState<ProjectKey>("space");
  const [isSiderOpen, setIsSiderOpen] = useState(false);
  const content = projectContent[selectedPage];
  return (
    <div id="canvas" className={`bg-[${content.canvas.color}]`}>
      <Sider
        isOpen={isSiderOpen}
        onClose={() => {}}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
      {content.canvas.component}
      <div className="fixed top-0">
        <div className="mb-2">{content.title}</div>
        <div className="mb-2">{content.description}</div>
        <div className="py-6 pl-10 lg:pl-[340px]">{content.projectLink}</div>
      </div>
      <div className="fixed bottom-0 w-screen pt-12 pb-4 px-12 flex justify-between bg-white z-20">
        <div>
          <Popover
            icon={
              <FaRocket
                // className={`text-4xl ${isBouncing ? "animate-bounce" : ""}`}
                size={36}
              />
            }
            text="View Portfolio"
            className="inline-block mr-2"
            onClick={() => {
              // setIsBouncing(true);
              // setTimeout(() => {
              //   setIsBouncing(false);
              // }, 500);
              setIsSiderOpen(!isSiderOpen);
            }}
          />
        </div>
        <div className="flex justify-end">
          {content.icons.map((icon) => icon)}
        </div>
      </div>
    </div>
  );
}
