import React, { useState } from "react";
import { Popover as P } from "@headlessui/react";

interface CustomPopoverProps {
  icon: React.ReactNode;
  text: string;
  className?: string;
  onClick?: () => void;
}

export default function CustomPopover({
  icon,
  text,
  className,
  onClick,
}: CustomPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <P
        className={
          className
            ? `relative flex justify-center ${className}`
            : "relative flex justify-center"
        }
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {({ open }) => (
          <>
            <P.Button
              className="focus:outline-none hover:text-gray-500"
              onClick={onClick}
            >
              {icon && icon}
            </P.Button>
            {isOpen && (
              <>
                <P.Panel
                  static
                  className="absolute top-[-40px] w-max bg-gray-900 text-white rounded-md text-xs p-2"
                >
                  {text}
                </P.Panel>
                <div id="arrow" className="absolute top-[-14px] bg-gray-900" />
              </>
            )}
          </>
        )}
      </P>
    </div>
  );
}
