import React, { useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import * as constants from "../constants";
import businessData from "../data/businesses.json";
import smNeighborhoodsData from "../data/smNeighborhoods.json";
import mdNeighborhoodsData from "../data/mdNeighborhoods.json";
// import InstancedItems from "./InstancedItems";
import useThreeCatelog from "../useThreeCatelog";

export function transformGridToThreeCoords(x: number, y: number) {
  return new THREE.Vector3(x * constants.SCALE, 0, y * constants.SCALE);
}

export default function Models() {
  const [showModels, setShowModels] = useState(true);
  const catelog = useThreeCatelog();

  // useFrame(({ camera }) => {
  //   // console.log(camera.zoom);
  //   if (camera.zoom < 250 && showModels) {
  //     setShowModels(false);
  //   } else if (camera.zoom >= 250 && !showModels) {
  //     setShowModels(true);
  //   }
  // });

  return (
    <>
      {/* <InstancedItems
        type="neighborhood"
        size="sm"
        items={smNeighborhoodsData.smNeighborhoods}
      />
      <InstancedItems
        type="neighborhood"
        size="md"
        items={mdNeighborhoodsData.mdNeighborhoods}
      />
      <InstancedItems
        type="business"
        size="sm"
        items={businessData.smBusinesses}
      />
      <InstancedItems
        type="business"
        size="md"
        items={businessData.mdBusinesses}
      /> */}
      {showModels && (
        <>
          {smNeighborhoodsData.smNeighborhoods.map((smNeighborhood, i) => (
            <mesh
              key={`smNeighborhood_${smNeighborhood.xCoord}_${smNeighborhood.yCoord}_${i}`}
              position={transformGridToThreeCoords(
                smNeighborhood.xCoord,
                smNeighborhood.yCoord
              )}
              material={catelog.neighborhood.sm.lod1.material}
              geometry={catelog.neighborhood.sm.lod1.geometry}
              rotation={[Math.PI / 2, 0, 0]}
              scale={constants.CAD_FACTOR * constants.SCALE}
            />
          ))}
          {mdNeighborhoodsData.mdNeighborhoods.map((mdNeighborhood, i) => (
            <mesh
              key={`mdNeighborhood_${mdNeighborhood.xCoord}_${mdNeighborhood.yCoord}_${i}`}
              position={transformGridToThreeCoords(
                mdNeighborhood.xCoord,
                mdNeighborhood.yCoord
              )}
              material={catelog.neighborhood.md.lod1.material}
              geometry={catelog.neighborhood.md.lod1.geometry}
              rotation={[Math.PI / 2, 0, 0]}
              scale={constants.CAD_FACTOR * constants.SCALE}
            />
          ))}
          {businessData.smBusinesses.map((smBusiness, i) => (
            <mesh
              key={`smBusiness_${smBusiness.xCoord}_${smBusiness.yCoord}_${i}`}
              position={transformGridToThreeCoords(
                smBusiness.xCoord,
                smBusiness.yCoord
              )}
              material={catelog.business.sm.lod1.material}
              geometry={catelog.business.sm.lod1.geometry}
              rotation={[Math.PI / 2, 0, 0]}
              scale={constants.CAD_FACTOR * constants.SCALE}
            />
          ))}
          {businessData.mdBusinesses.map((mdBusiness, i) => (
            <mesh
              key={`mdBusiness_${mdBusiness.xCoord}_${mdBusiness.yCoord}_${i}`}
              position={transformGridToThreeCoords(
                mdBusiness.xCoord,
                mdBusiness.yCoord
              )}
              material={catelog.business.md.lod1.material}
              geometry={catelog.business.md.lod1.geometry}
              rotation={[Math.PI / 2, 0, 0]}
              scale={constants.CAD_FACTOR * constants.SCALE}
            />
          ))}

          {/* {mapData.schools.map((school, i) => (
          <mesh
            key={`School_${school.xCoord}_${school.yCoord}_${i}`}
            name="drag-item"
            position={[school.xCoord, 0, school.yCoord]}
            material={schoolMaterial.Sch025LOD1}
            geometry={(schoolMesh as THREE.Mesh).geometry}
            rotation={[Math.PI / 2, 0, 0]}
            scale={constants.CAD_FACTOR*constants.SCALE}
            // onDoubleClick={handleDoubleClick}
          />
        ))} */}
        </>
      )}
      {/* {mapData.lgBusinesses.map((lgBusiness, i) => (
        <mesh
          key={`lgBusiness_${lgBusiness.xCoord}_${lgBusiness.yCoord}_${i}`}
          position={[lgBusiness.xCoord, 0, lgBusiness.yCoord]}
          material={lgBusinessMaterial.BusB100LOD1}
          geometry={(lgBusinessMesh as THREE.Mesh).geometry}
          rotation={[Math.PI / 2, 0, 0]}
          scale={constants.CAD_FACTOR*constants.SCALE}
        />
      ))} */}
    </>
  );
}
