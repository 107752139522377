export const INIT_CAM_POS_X = -1354;
export const INIT_CAM_POS_Y = 904;
export const INIT_CAM_POS_Z = 2510;
export const CAM_NEAR = 0.001;
export const CAM_FAR = 6000;
export const INIT_CAM_TARGET = [-37, 0, 251];
export const INIT_CAM_ZOOM = 30;

export const INIT_POLAR_ANGLE = 1.133;
export const INIT_AZIMUTHAL_ANGLE = -0.675;

export const INIT_ZOOM = 30;
export const MIN_ZOOM = 0.1;
export const MAX_ZOOM = 500;

// LOD
export const LOD_DISTS = [0, 45, 90, 500];
export const LOD_COUNT = LOD_DISTS.length;
export const LOD_ZOOM = 28;

// Scaling Factors
export const SCALE = 2;
// 1 CAD unit = 250 Threejs units
// export const CAD_FACTOR = 1 / 250;

export const CAD_FACTOR = 157.5;

export const ROTATION_VALS = [0, Math.PI / 2, Math.PI, -Math.PI / 2];

// Pie chart
export const colors = [
  "#27597B",
  "#16872A",
  "#349CFB",
  "#A0D2FF",
  "#DBEAFE",
  "#99C59C",
];

// Invest
export const INIT_INVEST_FUNDS = 1000000000;
