import { useGLTF } from "@react-three/drei";
import { useLoader } from "react-three-fiber";
import * as THREE from "three";

type ThreeCatalogEntry = {
  lod1: {
    geometry: THREE.BufferGeometry;
    material: THREE.Material;
  };
  lod4: {
    geometry: THREE.BufferGeometry;
    material: THREE.Texture;
  };
};

type ThreeCatelog = {
  [type: string]: {
    [size: string]: ThreeCatalogEntry;
  };
};

// Translates the geometry so that its origin is in the center
// This allows for rotating meshes that use this geometry and still maintaining its relative position
function translateGeometry(geo: THREE.BufferGeometry) {
  geo.computeBoundingBox();
  if (geo.boundingBox) {
    const centerX = (geo.boundingBox.max.x + geo.boundingBox.min.x) / 2;
    const centerY = (geo.boundingBox.max.y + geo.boundingBox.min.y) / 2;
    geo.translate(-centerX, -centerY, 0);
  }
}

// LOD4 Geometries
const smBoxGeometry = new THREE.BoxGeometry(1, 1, 0);
const mdBoxGeometry = new THREE.BoxGeometry(2, 2, 0);
const lgBoxGeometry = new THREE.BoxGeometry(4, 4, 0);

translateGeometry(smBoxGeometry);
translateGeometry(mdBoxGeometry);
translateGeometry(lgBoxGeometry);

export default function useThreeCatelog() {
  // LOD4 Textures
  const [
    smNeighborhoodLOD4,
    mdNeighborhoodLOD4,
    smBusinessLOD4,
    mdBusinessLOD4,
  ] = useLoader(THREE.TextureLoader, [
    "/models/Neighborhood/Nei025LOD4e-125.jpg",
    "/models/Neighborhood/Nei050LOD4.jpg",
    "/models/Business/Bus025LOD4.jpg",
    "/models/Business/Bus050LOD4-half.jpg",
    // eslint-disable-next-line prettier/prettier
  ]);

  // LOD1 Geometries and Materials
  const {
    nodes: { Bus025LOD1: smBusinessMesh },
    materials: smBusinessMaterial,
  } = useGLTF("/models/Business/Bus025LOD1.gltf");
  const {
    nodes: { Bus050LOD1: mdBusinessMesh },
    materials: mdBusinessMaterial,
  } = useGLTF("/models/Business/Bus050LOD1.gltf");
  //   const {
  //     nodes: { BusB100LOD1: lgBusinessMesh },
  //     materials: lgBusinessMaterial,
  //   } = useGLTF("/models/Business/BusB100LOD1.gltf");
  // const {
  //   nodes: { Sch025LOD1: schoolMesh },
  //   materials: schoolMaterial,
  // } = useGLTF("/models/School/Sch025LOD1.gltf");
  const {
    nodes: { Nei025LOD1: smNeighborhoodMesh },
    materials: smNeighborhoodMaterial,
  } = useGLTF("/models/Neighborhood/Nei025LOD1.gltf");
  const {
    nodes: { Nei050LOD1: mdNeighborhoodMesh },
    materials: mdNeighborhoodMaterial,
  } = useGLTF("/models/Neighborhood/Nei050LOD1.gltf");

  // Catelog
  const catelog: ThreeCatelog = {
    neighborhood: {
      sm: {
        lod1: {
          geometry: (smNeighborhoodMesh as THREE.Mesh).geometry,
          material: smNeighborhoodMaterial.Nei025LOD1,
        },
        lod4: {
          geometry: smBoxGeometry,
          material: smNeighborhoodLOD4,
        },
      },
      md: {
        lod1: {
          geometry: (mdNeighborhoodMesh as THREE.Mesh).geometry,
          material: mdNeighborhoodMaterial.Nei050LOD1,
        },
        lod4: {
          geometry: mdBoxGeometry,
          material: mdNeighborhoodLOD4,
        },
      },
      lg: {
        lod1: {
          geometry: smBoxGeometry,
          material: smNeighborhoodMaterial.Nei100LOD1,
        },
        lod4: {
          geometry: smBoxGeometry,
          material: mdNeighborhoodLOD4,
        },
      },
    },
    business: {
      sm: {
        lod1: {
          geometry: (smBusinessMesh as THREE.Mesh).geometry,
          material: smBusinessMaterial.Bus025LOD1,
        },
        lod4: {
          geometry: smBoxGeometry,
          material: smBusinessLOD4,
        },
      },
      md: {
        lod1: {
          geometry: (mdBusinessMesh as THREE.Mesh).geometry,
          material: mdBusinessMaterial.Bus050LOD1,
        },
        lod4: {
          geometry: mdBoxGeometry,
          material: mdBusinessLOD4,
        },
      },
      lg: {
        lod1: {
          geometry: smBoxGeometry,
          material: smBusinessMaterial.Bus100LOD1,
        },
        lod4: {
          geometry: smBoxGeometry,
          material: mdBusinessLOD4,
        },
      },
    },
  };
  return catelog;
}
