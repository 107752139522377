import React, { ReactNode } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import { TbBrandThreejs } from "react-icons/tb";
import { FaReact, FaGithub } from "react-icons/fa";
import { SiAmazonaws, SiMongodb, SiReact } from "react-icons/si";
import Popover from "../../../common/components/Popover";
import Earth from "./Earth";
import Camera from "../simulator/components/Camera";
import Models from "../simulator/components/Models";

const handlePopoverClick = (url: string) => {
  window.open(url, "_blank");
};

export type ProjectKey = "az" | "space";

interface ProjectI {
  key: ProjectKey;
  title: ReactNode;
  canvas: {
    color: string;
    component: ReactNode;
  };
  description: ReactNode;
  projectLink: ReactNode;
  card: {
    title: string;
  };
  icons: ReactNode[];
}

interface ProjectContentI {
  az: ProjectI;
  space: ProjectI;
}

const projectContent: ProjectContentI = {
  az: {
    key: "az",
    title: (
      <h1 className="text-black text-4xl sm:text-6xl md:text-8xl w-screen text-center lg:text-left lg:pl-80 mt-20">
        Arizona Futures
      </h1>
    ),
    canvas: {
      color: "#dfdacf",
      component: (
        <Canvas>
          <Camera />
          <Models />
        </Canvas>
      ),
    },
    description: (
      <p className="text-black w-64 pl-10 sm:w-96 lg:w-full lg:pl-[340px] text-left">
        Arizona Futures is an abstract, 3d map of Arizona. The app was built to
        provide insight into the AZ education system.
      </p>
    ),
    projectLink: (
      <a
        href="https://spencercreer.github.io/Hello_Worlds/"
        target="_blank"
        className="px-6 py-3 rounded-md border border-black text-black bg-transparent transition duration-300 hover:shadow-md hover:shadow-black"
        rel="noreferrer"
      >
        View Project
      </a>
    ),
    card: {
      title: "Arizona Futures",
    },
    icons: [
      <Popover
        key="aws"
        icon={<SiAmazonaws size={36} />}
        text="Hosted on AWS"
        className="inline-block mr-2"
      />,
      <Popover
        key="react"
        icon={<FaReact size={36} />}
        text="React"
        className="inline-block mr-2"
      />,
      <Popover
        key="threejs"
        icon={<TbBrandThreejs size={36} />}
        text="Three.js"
        className="inline-block mr-2"
      />,
      <Popover
        key="mongodb"
        icon={<SiMongodb size={36} />}
        text="MongoDB"
        className="inline-block mr-2"
      />,
    ],
  },
  space: {
    key: "space",
    title: (
      <h1 className="text-white text-4xl sm:text-6xl md:text-8xl w-screen text-center lg:text-left lg:pl-80 mt-20">
        Hello Worlds!
      </h1>
    ),
    canvas: {
      color: "#010101",
      component: (
        <Canvas>
          <ambientLight intensity={2} />
          <pointLight position={[6, -3, 0]} />
          <Stars />
          <Earth />
          <OrbitControls
            enableZoom
            enablePan={false}
            enableRotate={false}
            zoomSpeed={0.5}
            minDistance={3}
            maxDistance={30}
          />
        </Canvas>
      ),
    },
    description: (
      <p className="text-white w-64 pl-10 sm:w-96 lg:w-full lg:pl-[340px] text-left">
        In preparation for my AWS Certified Developer exam, I created this
        interactive 3D solar system model. I used several AWS services including
        CodePipeline, S3, ApiGateway, and DynamoDB.
      </p>
    ),
    projectLink: (
      <a
        href="http://hello-worlds-3920.s3-website-us-east-1.amazonaws.com/"
        target="_blank"
        className="px-6 py-3 w-[200px] rounded-md border border-white text-white bg-transparent transition duration-300 hover:shadow-md hover:shadow-white"
        rel="noreferrer"
      >
        View Project
      </a>
    ),
    card: {
      title: "Hello Worlds",
    },
    icons: [
      <Popover
        key="aws"
        icon={<SiAmazonaws size={36} />}
        text="Hosted on AWS"
        className="inline-block mr-2"
      />,
      // <Popover
      //   key="aws-api-gateway"
      //   icon={<SiAmazonapigateway size={36} />}
      //   text="AWS API Gateway"
      //   className="inline-block mr-2"
      // />,
      // <Popover
      //   key="aws-lambda"
      //   icon={<SiAwslambda size={36} />}
      //   text="AWS Lambda"
      //   className="inline-block mr-2"
      // />,
      // <Popover
      //   key="aws-dynamodb"
      //   icon={<SiAmazondynamodb size={36} />}
      //   text="AWS DynamoDB"
      //   className="inline-block mr-2"
      // />,
      <Popover
        key="react"
        icon={<SiReact size={36} />}
        text="React"
        className="inline-block mr-2"
      />,
      <Popover
        key="threejs"
        icon={<TbBrandThreejs size={36} />}
        text="Three.js"
        className="inline-block mr-2"
      />,
      <Popover
        key="repo"
        icon={<FaGithub size={36} />}
        text="Visit Repo"
        className="inline-block mr-2"
        onClick={() =>
          handlePopoverClick("https://github.com/spencercreer/Hello_Worlds")
        }
      />,
    ],
  },
};

export default projectContent;
