/* eslint-disable prettier/prettier */
import React from "react";
import { MapControls, OrthographicCamera } from "@react-three/drei";
import * as constants from "../constants";

export default function Camera() {
  return (
    <>
      <OrthographicCamera
        position={[
          constants.INIT_CAM_POS_X,
          constants.INIT_CAM_POS_Y,
          constants.INIT_CAM_POS_Z,
        ]}
        rotation={[0, 0, 0]}
        near={constants.CAM_NEAR}
        far={constants.CAM_FAR}
        zoom={constants.INIT_CAM_ZOOM}
        makeDefault
      />
      <MapControls
        minZoom={constants.MIN_ZOOM}
        maxZoom={constants.MAX_ZOOM}
        target={[-37, 0, 251]}
        maxPolarAngle={Math.PI / 2.5}
        rotateSpeed={0.2}
        dampingFactor={1}
        // autoRotate={autoRotation}
        autoRotateSpeed={0.5}
      />
    </>
  );
}
