import { useRef } from "react";
import * as THREE from "three";
import { useFrame, useLoader } from "@react-three/fiber";

function Earth() {
  const [colorMap, normalMap, specularMap, cloudsMap] = useLoader(
    THREE.TextureLoader,
    [
      "/textures/earth/8k_earth_daymap.jpg",
      "/textures/earth/8k_earth_normal_map.jpg",
      "/textures/earth/8k_earth_specular_map.jpg",
      "/textures/earth/8k_earth_clouds.jpg",
      // eslint-disable-next-line prettier/prettier
    ]
  );

  const earthMesh = useRef<THREE.Mesh>(null!);
  const cloudsMesh = useRef<THREE.Mesh>(null!);
  useFrame(({ clock }) => {
    earthMesh.current.rotation.y = clock.getElapsedTime() / 4;
    cloudsMesh.current.rotation.y = clock.getElapsedTime() / 3;
  });

  // const radius = planetData.equaRadius
  return (
    <group position={[3, -1, 0]}>
      <mesh ref={cloudsMesh}>
        <sphereGeometry args={[3.015, 32, 32]} />
        <meshPhongMaterial
          map={cloudsMap}
          opacity={0.4}
          depthWrite={true}
          transparent={true}
          side={THREE.DoubleSide}
        />
      </mesh>
      <mesh
        ref={earthMesh}
        // onClick={() => setActive(!active)}
      >
        <sphereGeometry args={[3, 32, 32]} />
        <meshPhongMaterial specularMap={specularMap} />
        <meshStandardMaterial
          map={colorMap}
          normalMap={normalMap}
          metalness={0.4}
          roughness={0.7}
        />
      </mesh>
    </group>
  );
}

export default Earth;
