import React, { ReactNode, Dispatch } from "react";
import projectContent, {
  ProjectKey,
} from "../../pages/portfolio/components/Projects";
import Card from "./Card";

interface SiderProps {
  isOpen: boolean;
  title?: ReactNode;
  subtitle?: string;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  isLoading?: boolean;
  selectedPage: ProjectKey;
  setSelectedPage: Dispatch<ProjectKey>;
  onClose: () => void;
}

export default function Sider({
  isOpen,
  title,
  subtitle,
  children,
  header,
  footer,
  className,
  //   onClose,
  selectedPage,
  setSelectedPage,
  isLoading = false,
}: SiderProps) {
  return (
    <div
      className={
        isOpen
          ? "fixed top-0 z-10 overflow-y-auto h-screen sm:w-[300px] w-screen transform text-white bg-white transition-all"
          : "fixed top-0 z-10 overflow-y-auto h-screen w-[0px] transform text-white bg-white transition-all"
      }
    >
      {isOpen && (
        <div className="px-2 space-y-3">
          <h3 className="text-black pt-4 text-left sm:w-[300px] w-screen hover:text-sky-500">
            Spencer Creer
          </h3>
          <h4 className="text-black pt-1 text-left sm:w-[300px] w-screen hover:text-sky-500">
            About
          </h4>
          <h4 className="text-black pt-1 text-left sm:w-[300px] w-screen">
            Personal Projects
          </h4>
          <Card
            id={projectContent.space.key}
            onClick={() => setSelectedPage(projectContent.space.key)}
            footer={
              <div className="h-1">{projectContent.space.card.title}</div>
            }
            selected={selectedPage === projectContent.space.key}
          />
          <Card
            id={projectContent.az.key}
            onClick={() => setSelectedPage(projectContent.az.key)}
            footer={<div className="h-1">{projectContent.az.card.title}</div>}
            selected={selectedPage === projectContent.az.key}
          />
          <a
            href="https://www.credly.com/badges/f10623dd-9da3-44da-888d-1f5c756d2d60/public_url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/aws-certified-developer-associate.png"
              alt="AWS Certified Developer Associate Badge"
              style={{ width: "100px", height: "auto" }}
            />
          </a>
        </div>
      )}
    </div>
  );
}
